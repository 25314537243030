<div
    class="grid-object-content ellipsis"
    [ngClass]="{
        error: (object | state) === 'error',
        warning: (object | state) === 'warning',
        none: (object | state) === 'disabled' || (object | state) === 'pending' || (object | state) === 'no_flow' || (object | state) === 'no_source'
    }"
    placement="top-right auto"
    *ngIf="!grid.thumbnails"
>
    <div class="w-100 ellipsis">
        <!-- {{ object | json }} -->
        <!-- Objects -->
        <zx-zec
            [model]="object"
            [showStatusIcon]="false"
            [showStatusText]="false"
            [showLink]="true"
            [showTag]="true"
            [searchTerm]="searchTerm"
            *ngIf="object.type === 'zec'"
        ></zx-zec>
        <zx-feeder
            [model]="object"
            [showStatusIcon]="false"
            [showStatusText]="false"
            [showLink]="true"
            [showTag]="true"
            [searchTerm]="searchTerm"
            *ngIf="object.type === 'feeder'"
        ></zx-feeder>
        <zx-cluster
            *ngIf="GridObjectsTypeGuard.type.isCluster(object)"
            [model]="object"
            [showStatusIcon]="false"
            [showStatusText]="false"
            [showLink]="true"
            [showTag]="true"
            [searchTerm]="searchTerm"
        ></zx-cluster>
        <zx-broadcaster
            *ngIf="GridObjectsTypeGuard.type.isBroadcaster(object)"
            [model]="object"
            [showStatusIcon]="false"
            [showStatusText]="false"
            [showLink]="true"
            [showTag]="true"
            [searchTerm]="searchTerm"
        ></zx-broadcaster>
        <zx-receiver
            [model]="object"
            [showStatusIcon]="false"
            [showStatusText]="false"
            [showLink]="true"
            [showTag]="true"
            [searchTerm]="searchTerm"
            *ngIf="object.type === 'receiver'"
        ></zx-receiver>
        <zx-source
            *ngIf="GridObjectsTypeGuard.type.isSource(object)"
            [model]="object"
            [showStatusIcon]="false"
            [showStatusText]="false"
            [showLink]="true"
            [showTag]="true"
            [searchTerm]="searchTerm"
        ></zx-source>
        <zx-mediaconnect-source
            *ngIf="GridObjectsTypeGuard.type.isMediaConnectSource(object)"
            [model]="object"
            [showStatusIcon]="false"
            [showStatusText]="false"
            [showLink]="true"
            [showTag]="true"
            [searchTerm]="searchTerm"
        ></zx-mediaconnect-source>
        <zx-adaptive
            *ngIf="GridObjectsTypeGuard.type.isAdaptiveChannel(object)"
            [model]="object"
            [showStatusIcon]="false"
            [showStatusText]="false"
            [showLink]="true"
            [showTag]="true"
            [searchTerm]="searchTerm"
        ></zx-adaptive>
        <zx-delivery
            *ngIf="GridObjectsTypeGuard.type.isDeliveryChannel(object)"
            [model]="object"
            [showStatusIcon]="false"
            [showStatusText]="false"
            [showLink]="true"
            [showTag]="true"
            [searchTerm]="searchTerm"
        ></zx-delivery>
        <zx-failover-channel
            *ngIf="GridObjectsTypeGuard.type.isFailoverChannel(object)"
            [model]="object"
            [showStatusIcon]="false"
            [showStatusText]="false"
            [showLink]="true"
            [showTag]="true"
            [searchTerm]="searchTerm"
        ></zx-failover-channel>
        <zx-mediaconnect-flow
            *ngIf="GridObjectsTypeGuard.type.isMediaConnectFlow(object)"
            [model]="object"
            [showStatusIcon]="false"
            [showStatusText]="false"
            [showLink]="true"
            [showTag]="true"
            [searchTerm]="searchTerm"
        ></zx-mediaconnect-flow>
        <zx-target
            *ngIf="GridObjectsTypeGuard.category.isTarget(object)"
            [model]="{ target: object, apiType: targetsService.getTargetApiType(object) }"
            [showStatusIcon]="false"
            [showStatusText]="false"
            [showLink]="true"
            [showTag]="true"
            [searchTerm]="searchTerm"
        ></zx-target>
        <!-- Last Error Message -->
        <div
            *ngIf="grid.display_detailed_status && ((object | state) === 'error' || (object | state) === 'warning')"
            title="{{ sharedService.getLastError(object)?.message }}"
            class="ellipsis"
        >
            <ngb-highlight
                title="{{ sharedService.getLastError(object)?.message }}"
                [result]="sharedService.getLastError(object)?.message"
                [term]="searchTerm"
            ></ngb-highlight>
        </div>
        <!-- State -->
        <div *ngIf="grid.display_detailed_status && (object | state) !== 'error' && (object | state) !== 'warning'">
            <span *ngIf="GridObjectsTypeGuard.category.isTarget(object); else defaultStatus">{{ object | targetStatusText | translate }}</span>
            <ng-template #defaultStatus>
                <span>{{ object | statusText | translate }}</span>
            </ng-template>
        </div>
        <!-- Target Channel -->
        <div class="ellipsis" *ngIf="sharedService.isTarget(object) && (object.adaptiveChannel || object.deliveryChannel || object.mediaconnectFlow)">
            <span class="label">{{ "CHANNEL" | translate }}:</span>
            <zx-adaptive *ngIf="object.adaptiveChannel" [model]="object.adaptiveChannel" [showLink]="true" [showStatusIcon]="false"></zx-adaptive>
            <zx-delivery *ngIf="object.deliveryChannel" [model]="object.deliveryChannel" [showLink]="true" [showStatusIcon]="false"></zx-delivery>
            <zx-mediaconnect-flow
                *ngIf="object.mediaconnectFlow"
                [model]="object.mediaconnectFlow"
                [showLink]="true"
                [showStatusIcon]="false"
            ></zx-mediaconnect-flow>
        </div>
        <!-- Bitrate -->
        <div *ngIf="grid.display_bitrate && (object.type === 'source' || object.type === 'mediaconnect_sources') && object.status">
            <span class="label">{{ "BITRATE" | translate }}:</span
            ><span *ngIf="object.status?.bitrate">{{ object.status?.bitrate | number : "1.0-0" }} kbps</span><span *ngIf="!object.status?.bitrate">-</span>
        </div>
        <!-- Target Bitrate -->
        <div *ngIf="grid.display_bitrate && sharedService.isTarget(object) && object.status">
            <span class="label">{{ "BITRATE" | translate }}:</span
            ><span *ngIf="object.status?.stats?.net_bitrate != null">{{ object.status?.stats?.net_bitrate / 1000 | number : "1.0-0" }} kbps</span
            ><span *ngIf="object.status?.stats?.net_bitrate == null">-</span>
        </div>
        <!-- TR101 -->
        <div
            *ngIf="
                grid.display_tr101 &&
                (object.type === 'source' || object.type === 'mediaconnect_sources') &&
                object.status &&
                object.status?.tr101 &&
                object.status?.tr101?.status &&
                object.status?.tr101?.status
            "
        >
            <span class="label">{{ "TR101" | translate }}:</span
            ><span>
                <!-- P1 -->
                <fa-icon *ngIf="object.status.tr101.status.p1_ok" icon="check-circle" size="1x" class="status-good me-1"></fa-icon>
                <fa-icon *ngIf="!object.status.tr101.status.p1_ok" icon="minus-circle" size="1x" class="status-bad me-1"></fa-icon>
                <!-- P2 -->
                <fa-icon *ngIf="object.status.tr101.status.p2_ok" icon="check-circle" size="1x" class="status-good"></fa-icon>
                <fa-icon *ngIf="!object.status.tr101.status.p2_ok" icon="minus-circle" size="1x" class="status-bad"></fa-icon
            ></span>
        </div>
        <div *ngIf="grid.display_tr101 && object.type === 'source' && object.status && object.status?.tr101 && !object.status?.tr101.status">
            <span class="label">{{ "TR101" | translate }}:</span><span>-</span>
        </div>
        <!-- Latency -->
        <div *ngIf="grid.display_latency && object.type === 'source'">
            <span class="label">{{ "LATENCY" | translate }}:</span><span>{{ object.latency || "-" }}</span>
        </div>
        <!-- IP -->
        <div *ngIf="grid.display_ip && (object.type === 'receiver' || object.type === 'broadcaster' || object.type === 'feeder' || object.type === 'zec')">
            <span class="label">{{ "IP" | translate }}:</span><span>{{ object.status?.source_ip || "-" }}</span>
        </div>
        <!-- Target IP -->
        <div *ngIf="grid.display_ip && sharedService.isTarget(object) && object.status">
            <span class="label">{{ "IP" | translate }}:</span><span *ngIf="object.status?.ip != null">{{ object.status?.ip }}</span
            ><span *ngIf="object.status?.ip == null">-</span>
        </div>
        <!-- CPU -->
        <div *ngIf="grid.display_cpu && (object.type === 'receiver' || object.type === 'broadcaster' || object.type === 'feeder' || object.type === 'zec')">
            <span class="label">{{ "CPU" | translate }}:</span><span *ngIf="object.status?.cpu">{{ object.status?.cpu / 100 | percent : "1.0-1" }}</span
            ><span *ngIf="!object.status?.cpu">-</span>
        </div>
        <!-- RAM -->
        <div *ngIf="grid.display_ram && (object.type === 'broadcaster' || object.type === 'feeder' || object.type === 'zec')">
            <span class="label">{{ "RAM" | translate }}:</span><span *ngIf="object.status?.ram">{{ object.status?.ram / 100 | percent : "1.0-1" }}</span
            ><span *ngIf="!object.status?.ram">-</span>
        </div>
        <!-- In Bitrate -->
        <div *ngIf="grid.display_bandwidth && (object.type === 'broadcaster' || object.type === 'zec')">
            <span class="label">{{ "IN_BITRATE" | translate }}:</span
            ><span *ngIf="object.status?.input_kbps">{{ object.status?.input_kbps | number : "1.0-0" }} kbps</span
            ><span *ngIf="!object.status?.input_kbps">-</span>
        </div>
        <!-- Out Bitrate -->
        <div *ngIf="grid.display_bandwidth && (object.type === 'broadcaster' || object.type === 'zec')">
            <span class="label">{{ "OUT_BITRATE" | translate }}:</span
            ><span *ngIf="object.status?.output_kbps">{{ object.status?.output_kbps | number : "1.0-0" }} kbps</span
            ><span *ngIf="!object.status?.output_kbps">-</span>
        </div>
        <!-- Version -->
        <div *ngIf="grid.display_version && (object.type === 'receiver' || object.type === 'broadcaster' || object.type === 'feeder' || object.type === 'zec')">
            <span class="label">{{ "VERSION" | translate }}:</span>
            <ngb-highlight [result]="object.status?.about?.version || '-' | version" [term]="searchTerm"></ngb-highlight>
        </div>
    </div>
</div>
<div class="grid-object-content grid-thumbnail" placement="top-right auto" *ngIf="grid.thumbnails">
    <!-- Image Thumbnail -->
    <app-source-thumbnail
        *ngIf="GridObjectsTypeGuard.type.isSource(object)"
        [source]="object"
        [info]="true"
        [overlay]="overlay"
        [alertOverlay]="displayAlerts"
        [audioOverlay]="displayAudio"
        [timeOverlay]="displayTime"
        [infoBtm]="infoBtm"
        [grid]="true"
        [showTag]="grid.sources && grid.targets"
        [searchTerm]="searchTerm"
        [refreshInterval]="grid.thumbnails_refresh_interval"
    ></app-source-thumbnail>
    <app-target-thumbnail
        *ngIf="GridObjectsTypeGuard.category.isTarget(object)"
        [target]="object"
        [info]="true"
        [overlay]="overlay"
        [alertOverlay]="displayAlerts"
        [audioOverlay]="displayAudio"
        [timeOverlay]="displayTime"
        [infoBtm]="infoBtm"
        [grid]="true"
        [showTag]="grid.sources && grid.targets"
        [searchTerm]="searchTerm"
        [refreshInterval]="grid.thumbnails_refresh_interval"
    ></app-target-thumbnail>
</div>
