<div class="grid-detail-container" #gridGroupContent>
    <content-loading-animation *ngIf="loadingDetails"></content-loading-animation>

    <div class="grid-page" [hidden]="loadingDetails" [ngClass]="{ expanded: isExpanded }">
        <div class="title-bar" [ngClass]="{ 'p-0 border-0': !showTopBar }">
            <div class="title" *ngIf="showTopBar">
                <h1 class="ellipsis" title="{{ gridGroup?.name }}">
                    <button type="button" class="btn btn-round me-1 back-button" (click)="back()" title="{{ 'BACK' | translate }}">
                        <fa-icon icon="chevron-left" size="1x"></fa-icon></button
                    >{{ gridGroup?.name }}
                </h1>
            </div>
            <div class="actions" *ngIf="showTopBar">
                <div class="form-inline">
                    <div class="btn-group me-2" role="group" *ngIf="canEditGridGroup(gridGroup)">
                        <button type="button" class="btn btn-outline-primary" (click)="editGridGroup(gridGroup.id)" title="{{ 'EDIT' | translate }}">
                            <fa-icon icon="pencil" size="sm"></fa-icon>
                            <span class="d-none d-md-inline-block ms-1">{{ "EDIT" | translate }}</span>
                        </button>
                        <button type="button" class="btn btn-outline-primary" (click)="cloneGridGroup(gridGroup.id)" title="{{ 'CLONE' | translate }}">
                            <fa-icon [icon]="['far', 'clone']" size="sm"></fa-icon>
                            <span class="d-none d-md-inline-block ms-1">{{ "CLONE" | translate }}</span>
                        </button>
                    </div>
                    <div class="form-group mb-0 me-2" *ngIf="gridGroup?.mode !== 'stack'">
                        <div ngbDropdown placement="bottom-right">
                            <button class="btn btn-outline-secondary" id="settingsDropdown" ngbDropdownToggle title="{{ 'SETTINGS' | translate }}">
                                <fa-icon icon="cog"></fa-icon>
                                <span class="sr-only">{{ "SETTINGS" | translate }}</span>
                            </button>
                            <div ngbDropdownMenu aria-labelledby="settingsDropdown" class="shadow-sm">
                                <button class="text-secondary" title="{{ 'FIT_TO_SCREEN' | translate }}" ngbDropdownItem (click)="fitToScreen = !fitToScreen">
                                    <fa-icon [icon]="['far', 'check-square']" *ngIf="fitToScreen"></fa-icon>
                                    <fa-icon [icon]="['far', 'square']" *ngIf="!fitToScreen"></fa-icon>
                                    {{ "FIT_TO_SCREEN" | translate }}
                                </button>
                            </div>
                        </div>
                    </div>
                    <button type="button" class="btn btn-outline-secondary me-2" (click)="refresh()" title="{{ 'REFRESH' | translate }}">
                        <fa-icon icon="sync-alt" size="sm" [spin]="refreshing"></fa-icon>
                        <span class="sr-only">{{ "REFRESH" | translate }}</span>
                    </button>
                    <button type="button" class="btn btn-outline-secondary" (click)="expand()" title="{{ 'EXPAND' | translate }}" *ngIf="!isExpanded">
                        <fa-icon icon="expand" size="sm"></fa-icon>
                        <span class="sr-only">{{ "EXPAND" | translate }}</span>
                    </button>
                    <button type="button" class="btn btn-outline-secondary" (click)="contract()" title="{{ 'CONTRACT' | translate }}" *ngIf="isExpanded">
                        <fa-icon icon="compress" size="sm"></fa-icon>
                        <span class="sr-only">{{ "CONTRACT" | translate }}</span>
                    </button>
                </div>
            </div>
            <div class="toggle-topbar-btn" *ngIf="isExpanded" (click)="toggleTopBar()">
                <fa-icon icon="angle-double-up" size="xs" *ngIf="showTopBar"></fa-icon>
                <fa-icon icon="angle-double-down" size="xs" *ngIf="!showTopBar"></fa-icon>
            </div>
        </div>

        <div class="panel-container">
            <div class="grid-group-panel">
                <!-- Stack -->
                <div class="grid-group-container stacked" *ngIf="gridGroup?.mode === 'stack'">
                    <div class="grid-group-content grid-group-layout" #gridGroupContent [ngClass]="{ notFitToScreen: !fitToScreen }">
                        <div class="grid-group-row">
                            <div class="grid-group-row">
                                <div class="details-content" #scrollArea>
                                    <div class="scroll-content">
                                        <ng-container *ngIf="!gridGroupLoading">
                                            <app-layout-buttons
                                                (saveLayout)="saveLayout()"
                                                (resetLayout)="resetLayout()"
                                                [showRevertButton]="false"
                                                [layoutChanged]="layoutChanged"
                                                [isLocked]="isLocked"
                                                (lockChanged)="lockChanged($event)"
                                                [savingLayout]="layoutChanging"
                                            ></app-layout-buttons>
                                            <app-widget-section
                                                [widgets]="gridGroupWidgets"
                                                (widgetsChange)="onWidgetsLayoutChange($event)"
                                                [isMultiSelect]="true"
                                                [isLocked]="isLocked"
                                                [isDuplicateComponent]="true"
                                                [showLinkTitle]="true"
                                                (linkClick)="goToLink($event)"
                                            ></app-widget-section>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Cycle -->
                <div class="grid-group-container cycle" *ngIf="gridGroup?.mode === 'cycle'">
                    <div class="grid-group-content" #gridGroupContent [ngClass]="{ notFitToScreen: !fitToScreen }">
                        <div class="grid-group-row" *ngFor="let grid of grids$ | async">
                            <h2>
                                <a href="javascript:void(0)" title="{{ grid.name }}" (click)="goToGrid(grid.id)">
                                    {{ grid.name }}
                                </a>
                            </h2>
                            <zx-grid [id]="grid.id" [controls]="false" [fitToScreen]="fitToScreen"></zx-grid>
                        </div>
                    </div>
                    <!-- Pagination -->
                    <div class="grid-group-bottom" #gridGroupBottom [ngClass]="{ 'p-0 border-0': !showBottomBar, 'mb-3': !fitToScreen }">
                        <div class="toggle-bottombar-btn" *ngIf="isExpanded" (click)="toggleBottomBar()">
                            <fa-icon icon="angle-double-up" size="xs" *ngIf="!showBottomBar"></fa-icon>
                            <fa-icon icon="angle-double-down" size="xs" *ngIf="showBottomBar"></fa-icon>
                        </div>
                        <div class="d-flex flex-wrap grid-gap-3" *ngIf="showBottomBar">
                            <div>
                                <ngb-pagination [collectionSize]="total$ | async" [(page)]="page" [pageSize]="pageSize" [maxSize]="2"></ngb-pagination>
                            </div>
                            <div class="mt-2">
                                <small>{{ "SHOWING" | translate }}: {{ startNum }} of {{ total$ | async }}</small>
                            </div>
                            <!-- Cycle -->
                            <div>
                                <div class="form-inline">
                                    <div class="form-group stay-inline mb-0">
                                        <button
                                            type="button"
                                            class="btn btn-outline-primary me-2"
                                            (click)="toggleGridCycle(cyclePaginationInterval)"
                                            title="{{ 'AUTO_PAGINATION' | translate }}"
                                        >
                                            <fa-icon icon="play" size="xs" *ngIf="!cyclePagination"></fa-icon>
                                            <fa-icon icon="pause" size="xs" *ngIf="cyclePagination"></fa-icon>
                                        </button>
                                        <label class="sr-only" for="cycle_pagination_interval">{{ "CYCLE_GRIDS_EVERY" | translate }}</label>
                                        <input
                                            type="number"
                                            name="cycle_pagination_interval"
                                            class="form-control maxw-60px"
                                            [(ngModel)]="cyclePaginationInterval"
                                            (ngModelChange)="intervalChange()"
                                        />
                                        <label class="ms-1">{{ "SECONDS" | translate | lowercase }}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
